.button {
    font: inherit;
    border: none;
    background-color: #747474;
    color: white;
    padding: 0.75rem 1.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
    font-weight: bold;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .clickable:hover,
  .clickable:active {
    cursor: pointer;
    background-color: #646464;
  }
  
  /* .icon {
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.5rem;
  } */
  


  /* .headerText1 {
    margin-left: 5px;
  } */

  .download {
    text-decoration: none;
  }

  .icon {
    margin-right: 5px
  }


  @media screen and (max-width: 249px) {
    .button {
      padding: 0.5rem 1rem;
    }
  
  }

  