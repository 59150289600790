.teamContainer {
    background-color: rgb(151, 151, 151);
    text-align: center;
    margin: 0 0px;
    padding-bottom: 6.5rem;
}

.teamContainer:after {
  content: "";
  display: table;
  clear: both;
}

.pageTitle {
    font-size: 69px;
    margin-bottom: 1rem;
    font-weight: 700;
    color:rgb(50,50,50);
    letter-spacing: 3px;
    padding-top: 3rem;
    margin-bottom: 3rem;
}

.header2 {
    display: none;
}

.cardHolder {
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 600px) {
    .cardHolder {
      flex-direction: column;
      align-items: center;
    }
  }


  @media screen and (max-width: 955px) {
    .pageTitle {
      margin-bottom: 2rem;
    }
  }



  @media screen and (max-width: 730px) {
    .pageTitle {
      font-size: 45px;
    }
  }

  @media screen and (max-width: 420px) {
    .header1 {
        display: none;
    }
    .header2 {
        display: block;
    }
  }