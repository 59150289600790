.error {
  color: red;
}

.background {
  position: relative;
  background: url('./assets/Background.png') no-repeat center center fixed;
  background-size: cover;

  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  
}



.mainDiv {
  min-height: 100vh;
  min-width: 100vw;

  overflow-x: hidden;
  overflow-y: scroll;
}

.mainDiv::-webkit-scrollbar {
  display: none;
}

.centeringDiv {

  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);

  width: 59rem;
  margin: 0 auto;


  background-color: rgba(116, 116, 116, 1);
  
  padding: 8rem;
  padding-bottom: 6rem;
  padding-top: 8rem;
  text-align: center;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  border-radius: 5px;
}


.buttonDiv {
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.mintButton {
    font: inherit;
    border: none;
    background-color: rgb(0,218,11);
    color: white;
    padding: 0.75rem 2rem;
    font-size: 20px;
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 0.2rem;
}



.mintingSoon {
  color: red;
  font-size: 24px;
  font-weight: bold;
}


.clickable:hover,
.clickable:active {
  cursor: pointer;
  background-color: #bd0c0c;
}

.project {
  position: relative;
  top: 0;
}







/* form starting stylings ------------------------------- */

.formLabel {
  
  font-size: 22px;
  margin-right: 0.75rem;
  font-weight: 600;
}

.formError {
  color: red;
  font-size: 20px;
}

.projectLabel {
  
  font-size: 18px;
  margin-right: 0.5rem;
  font-weight: 600;
}

input 				{
  font-size:18px;
  /* background-color: black; */
  color: black;
  background-color: rgba(231, 231, 231, 0.50);
  padding:10px 10px 5px 5px;
  text-align: center;

  width:14rem;
  border:none;
  /* border-bottom:1px solid black; */
  border:1px solid black;
}
input::placeholder {
  color: rgba(0, 0, 0, 0.6);
}
input:focus 		{ outline:none; }



@media screen and (max-height: 950px) {
  .background {
    margin: 0;
    min-height: 925px;
    min-width: 100vw;
    overflow: auto;
  }


  .centeringDiv {
    width: 0 auto;
    margin: 0 auto;
  }
}

@media screen and (max-width: 955px) {
  .background {
    margin: 0;
    height: 0 auto;
    min-width: 100vw;
    overflow: auto;
  }

  
  .centeringDiv {
    height: auto;
    width: auto;
    margin: 0 auto;
    padding: 4rem;
    padding-top: 4rem;
    margin-bottom: 7rem;
  }


  .formLabel {
    font-size: 18px;
    margin-right: 0.75rem;
    font-weight: 600;
  }

  .formError {
    font-size: 18px;
  }

  .mintButton {
    padding: 0.75rem 2rem;
    font-size: 18px;
    margin-top: 0.2rem;
  }

  .projectLabel {
    font-size: 16px;
  }

  .mintingSoon {
    font-size: 20px;
  }

  input 				{
    font-size: 16px;
    width: 8rem;
  }

  .buttonDiv {
    margin-bottom: 4rem;
  }
  
}




@media screen and (max-width: 730px) {
  .background {
    margin: 0;
    min-height: 900px;
    min-width: 100vw;
    overflow: auto;
  }

  .centeringDiv {
    height: auto;
    width: 330px;
    margin: 0 auto;
    padding-top: 3.5rem;
    margin-bottom: 7rem;
  }

  .formLabel {
    font-size: 16px;
  }

  .formError {
    font-size: 16px;
  }

  .mintButton {
    padding: 0.75rem 2rem;
    font-size: 16px;
    margin-top: 0.2rem;
  }

  .projectLabel {
    font-size: 16px;
  }

  .mintingSoon {
    font-size: 18px;
  }
  
  input 				{
    font-size: 16px;
    width: 6rem;
  }
}




@media screen and (max-width: 359px) {
  .background {
    margin: 0;
    min-height: 850px;
    min-width: 100vw;
    overflow: auto;
  }

  .centeringDiv {
    height: auto;
    width: 265px;
    margin: 0 auto;
    margin-bottom: 5rem;

    padding-bottom: 3rem;
    padding-top: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }


  .formLabel {
    font-size: 15px;
  }

  .formError {
    font-size: 15px;
  }

  .mintButton {
    padding: 0.75rem 2rem;
    font-size: 15px;
    margin-top: 0.2rem;
  }

  .projectLabel {
    font-size: 15px;
  }

  .mintingSoon {
    font-size: 17px;
  }
  
  input 				{
    font-size: 15px;
    width: 6rem;
  }
}
