.timelineContainer{
    text-align: center;
}

.pageTitle {
    font-size: 69px;
    margin-bottom: 1rem;
    font-weight: 700;
    color:rgb(50,50,50);
    letter-spacing: 3px;
    padding-top: 3rem;
    margin-bottom: 3rem;
  }







.timeline {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem;
    text-align: left;
    color:rgb(50,50,50);
    margin-bottom: 5.5rem;
}
  
  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: rgb(80,80,80);
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  /* Container around content */
  .container {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
  }
  
  /* The circles on the timeline */
  .container::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    right: -12px;
    background-color: #f7f7f7;
    border: 4px solid red;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  /* Place the container to the left */
  .left {
    left: 0;
  }
  
  /* Place the container to the right */
  .right {
    left: 50%;
  }
  
  /* Add arrows to the left container (pointing right) */
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid #f7f7f7;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #f7f7f7;
  }
  
  /* Add arrows to the right container (pointing left) */
  .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #f7f7f7;
    border-width: 10px 10px 10px 0;
    border-color: transparent #f7f7f7 transparent transparent;
  }
  
  /* Fix the circle for containers on the right side */
  .right::after {
    left: -12px;
  }
  
  /* The actual content */
  .content {
    padding: 20px 30px;
    background-color: #f7f7f7;
    position: relative;
    border-radius: 6px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 600px) {
    /* Place the timelime to the left */
    .timeline::after {
    left: 31px;
    }
    
    /* Full-width containers */
    .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
    }
    
    /* Make sure that all arrows are pointing leftwards */
    .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
    }
  
    /* Make sure all circles are at the same spot */
    .left::after, .right::after {
    left: 15px;
    }
    
    /* Make all right containers behave like the left ones */
    .right {
    left: 0%;
    }
}




/* ------------------------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 955px) {
    .pageTitle {
      margin-bottom: 2rem;
    }
  }



  @media screen and (max-width: 730px) {
    .pageTitle {
      font-size: 45px;
    }
  }
