.headTitle {
    font-size: 69px;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  
  .mainText {
    font-size: 22px;
    margin-bottom: 4rem;
    font-weight: 600;
  }

  .bottomText {
    font-size: 22px;
    margin-bottom: 0.75rem;
    font-weight: 600;
  }



  @media screen and (max-width: 955px) {
    .headTitle {
      font-size: 40px;
      margin-bottom: 1rem;
    }

    .mainText {
      font-size: 18px;
      margin-bottom: 3rem;
    }

    .bottomText {
      font-size: 18px;
      margin-bottom: 0.75rem;
    }
  }



  @media screen and (max-width: 730px) {
    .headTitle {
      font-size: 35px;
      margin-bottom: 1rem;
    }

    .mainText {
      font-size: 16px;
      margin-bottom: 3rem;
    }

    .bottomText {
      font-size: 16px;
      margin-bottom: 0.75rem;
    }
  }
  

  @media screen and (max-width: 359px) {
    .headTitle {
      font-size: 35px;
      margin-bottom: 1rem;
    }

    .mainText {
      font-size: 15px;
      margin-bottom: 3rem;
    }

    .bottomText {
      font-size: 15px;
      margin-bottom: 0.75rem;
    }
  }
