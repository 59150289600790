@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap);
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(230,230,230);
}

code {
  font-family: 'Source Sans Pro', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App_error__1umzI {
  color: red;
}

.App_background__3CDGK {
  position: relative;
  background: url(/static/media/Background.c4b153f9.png) no-repeat center center fixed;
  background-size: cover;

  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  
}



.App_mainDiv__3Jel7 {
  min-height: 100vh;
  min-width: 100vw;

  overflow-x: hidden;
  overflow-y: scroll;
}

.App_mainDiv__3Jel7::-webkit-scrollbar {
  display: none;
}

.App_centeringDiv__11F3y {

  position: absolute;
  top: 50%; left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);

  width: 59rem;
  margin: 0 auto;


  background-color: rgba(116, 116, 116, 1);
  
  padding: 8rem;
  padding-bottom: 6rem;
  padding-top: 8rem;
  text-align: center; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  border-radius: 5px;
}


.App_buttonDiv__yQx2h {
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.App_mintButton__1sebJ {
    font: inherit;
    border: none;
    background-color: rgb(0,218,11);
    color: white;
    padding: 0.75rem 2rem;
    font-size: 20px;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 0.2rem;
}



.App_mintingSoon__14P50 {
  color: red;
  font-size: 24px;
  font-weight: bold;
}


.App_clickable__1nK9C:hover,
.App_clickable__1nK9C:active {
  cursor: pointer;
  background-color: #bd0c0c;
}

.App_project__S3ZaW {
  position: relative;
  top: 0;
}







/* form starting stylings ------------------------------- */

.App_formLabel__1M7Lt {
  
  font-size: 22px;
  margin-right: 0.75rem;
  font-weight: 600;
}

.App_formError__3Sp57 {
  color: red;
  font-size: 20px;
}

.App_projectLabel__H7FEn {
  
  font-size: 18px;
  margin-right: 0.5rem;
  font-weight: 600;
}

input 				{
  font-size:18px;
  /* background-color: black; */
  color: black;
  background-color: rgba(231, 231, 231, 0.50);
  padding:10px 10px 5px 5px;
  text-align: center;

  width:14rem;
  border:none;
  /* border-bottom:1px solid black; */
  border:1px solid black;
}
input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
input::placeholder {
  color: rgba(0, 0, 0, 0.6);
}
input:focus 		{ outline:none; }



@media screen and (max-height: 950px) {
  .App_background__3CDGK {
    margin: 0;
    min-height: 925px;
    min-width: 100vw;
    overflow: auto;
  }


  .App_centeringDiv__11F3y {
    width: 0 auto;
    margin: 0 auto;
  }
}

@media screen and (max-width: 955px) {
  .App_background__3CDGK {
    margin: 0;
    height: 0 auto;
    min-width: 100vw;
    overflow: auto;
  }

  
  .App_centeringDiv__11F3y {
    height: auto;
    width: auto;
    margin: 0 auto;
    padding: 4rem;
    padding-top: 4rem;
    margin-bottom: 7rem;
  }


  .App_formLabel__1M7Lt {
    font-size: 18px;
    margin-right: 0.75rem;
    font-weight: 600;
  }

  .App_formError__3Sp57 {
    font-size: 18px;
  }

  .App_mintButton__1sebJ {
    padding: 0.75rem 2rem;
    font-size: 18px;
    margin-top: 0.2rem;
  }

  .App_projectLabel__H7FEn {
    font-size: 16px;
  }

  .App_mintingSoon__14P50 {
    font-size: 20px;
  }

  input 				{
    font-size: 16px;
    width: 8rem;
  }

  .App_buttonDiv__yQx2h {
    margin-bottom: 4rem;
  }
  
}




@media screen and (max-width: 730px) {
  .App_background__3CDGK {
    margin: 0;
    min-height: 900px;
    min-width: 100vw;
    overflow: auto;
  }

  .App_centeringDiv__11F3y {
    height: auto;
    width: 330px;
    margin: 0 auto;
    padding-top: 3.5rem;
    margin-bottom: 7rem;
  }

  .App_formLabel__1M7Lt {
    font-size: 16px;
  }

  .App_formError__3Sp57 {
    font-size: 16px;
  }

  .App_mintButton__1sebJ {
    padding: 0.75rem 2rem;
    font-size: 16px;
    margin-top: 0.2rem;
  }

  .App_projectLabel__H7FEn {
    font-size: 16px;
  }

  .App_mintingSoon__14P50 {
    font-size: 18px;
  }
  
  input 				{
    font-size: 16px;
    width: 6rem;
  }
}




@media screen and (max-width: 359px) {
  .App_background__3CDGK {
    margin: 0;
    min-height: 850px;
    min-width: 100vw;
    overflow: auto;
  }

  .App_centeringDiv__11F3y {
    height: auto;
    width: 265px;
    margin: 0 auto;
    margin-bottom: 5rem;

    padding-bottom: 3rem;
    padding-top: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }


  .App_formLabel__1M7Lt {
    font-size: 15px;
  }

  .App_formError__3Sp57 {
    font-size: 15px;
  }

  .App_mintButton__1sebJ {
    padding: 0.75rem 2rem;
    font-size: 15px;
    margin-top: 0.2rem;
  }

  .App_projectLabel__H7FEn {
    font-size: 15px;
  }

  .App_mintingSoon__14P50 {
    font-size: 17px;
  }
  
  input 				{
    font-size: 15px;
    width: 6rem;
  }
}

.appTimeline_timelineContainer__1DBhn{
    text-align: center;
}

.appTimeline_pageTitle__NtikI {
    font-size: 69px;
    margin-bottom: 1rem;
    font-weight: 700;
    color:rgb(50,50,50);
    letter-spacing: 3px;
    padding-top: 3rem;
    margin-bottom: 3rem;
  }







.appTimeline_timeline__24pSe { /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem;
    text-align: left;
    color:rgb(50,50,50);
    margin-bottom: 5.5rem;
}
  
  /* The actual timeline (the vertical ruler) */
  .appTimeline_timeline__24pSe::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: rgb(80,80,80);
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  /* Container around content */
  .appTimeline_container__o6f1s { /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
  }
  
  /* The circles on the timeline */
  .appTimeline_container__o6f1s::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    right: -12px;
    background-color: #f7f7f7;
    border: 4px solid red;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  /* Place the container to the left */
  .appTimeline_left__17A6k {
    left: 0;
  }
  
  /* Place the container to the right */
  .appTimeline_right__3NZRx {
    left: 50%;
  }
  
  /* Add arrows to the left container (pointing right) */
  .appTimeline_left__17A6k::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid #f7f7f7;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #f7f7f7;
  }
  
  /* Add arrows to the right container (pointing left) */
  .appTimeline_right__3NZRx::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #f7f7f7;
    border-width: 10px 10px 10px 0;
    border-color: transparent #f7f7f7 transparent transparent;
  }
  
  /* Fix the circle for containers on the right side */
  .appTimeline_right__3NZRx::after {
    left: -12px;
  }
  
  /* The actual content */
  .appTimeline_content__2iAmf {
    padding: 20px 30px;
    background-color: #f7f7f7;
    position: relative;
    border-radius: 6px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 600px) {
    /* Place the timelime to the left */
    .appTimeline_timeline__24pSe::after {
    left: 31px;
    }
    
    /* Full-width containers */
    .appTimeline_container__o6f1s {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
    }
    
    /* Make sure that all arrows are pointing leftwards */
    .appTimeline_container__o6f1s::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
    }
  
    /* Make sure all circles are at the same spot */
    .appTimeline_left__17A6k::after, .appTimeline_right__3NZRx::after {
    left: 15px;
    }
    
    /* Make all right containers behave like the left ones */
    .appTimeline_right__3NZRx {
    left: 0%;
    }
}




/* ------------------------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 955px) {
    .appTimeline_pageTitle__NtikI {
      margin-bottom: 2rem;
    }
  }



  @media screen and (max-width: 730px) {
    .appTimeline_pageTitle__NtikI {
      font-size: 45px;
    }
  }

.appTeam_teamContainer__XWn5I {
    background-color: rgb(151, 151, 151);
    text-align: center;
    margin: 0 0px;
    padding-bottom: 6.5rem;
}

.appTeam_teamContainer__XWn5I:after {
  content: "";
  display: table;
  clear: both;
}

.appTeam_pageTitle__1KvFB {
    font-size: 69px;
    margin-bottom: 1rem;
    font-weight: 700;
    color:rgb(50,50,50);
    letter-spacing: 3px;
    padding-top: 3rem;
    margin-bottom: 3rem;
}

.appTeam_header2__VgeQi {
    display: none;
}

.appTeam_cardHolder__3gE1s {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}


@media screen and (max-width: 600px) {
    .appTeam_cardHolder__3gE1s {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
    }
  }


  @media screen and (max-width: 955px) {
    .appTeam_pageTitle__1KvFB {
      margin-bottom: 2rem;
    }
  }



  @media screen and (max-width: 730px) {
    .appTeam_pageTitle__1KvFB {
      font-size: 45px;
    }
  }

  @media screen and (max-width: 420px) {
    .appTeam_header1__3x9Cc {
        display: none;
    }
    .appTeam_header2__VgeQi {
        display: block;
    }
  }
.Header_header__zSTUo {
    position: fixed;
    top: 0;
    left: 0;
    width: 94%;
    height: 6rem;
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 3%;
    z-index: 10;
  }

  .Header_main-image__2Q3So {
    width: 5%;
    height: 7rem;
    z-index: 0;
  }
  
  .Header_main-image__2Q3So img {
    height: 100%;
    object-fit: cover;
  }
.HeaderMetaMaskButton_button__3Y1gP {
    font: inherit;
    border: none;
    background-color: #747474;
    color: white;
    padding: 0.75rem 1.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 25px;
    font-weight: bold;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .HeaderMetaMaskButton_clickable__25Bmp:hover,
  .HeaderMetaMaskButton_clickable__25Bmp:active {
    cursor: pointer;
    background-color: #646464;
  }
  
  /* .icon {
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.5rem;
  } */
  


  /* .headerText1 {
    margin-left: 5px;
  } */

  .HeaderMetaMaskButton_download__3FgNx {
    text-decoration: none;
  }

  .HeaderMetaMaskButton_icon__ogY0o {
    margin-right: 5px
  }


  @media screen and (max-width: 249px) {
    .HeaderMetaMaskButton_button__3Y1gP {
      padding: 0.5rem 1rem;
    }
  
  }

  
.Footer_footerBasic__2JtvV {
    padding:40px 0;
    background-color:#f7f7f7;
    color:#4b4c4d;
  }
  
  .Footer_footerBasic__2JtvV ul {
    padding:0;
    list-style:none;
    text-align:center;
    font-size:18px;
    line-height:1.6;
    margin-bottom:0;
  }
  
  .Footer_footerBasic__2JtvV li {
    padding:0 10px;
  }
  
  .Footer_footerBasic__2JtvV ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.8;
  }
  
  .Footer_footerBasic__2JtvV ul a:hover {
    opacity:1;
  }
  
  .Footer_footerBasic__2JtvV .Footer_social__2M4qp {
    text-align:center;
    padding-bottom:25px;
  }
  
  .Footer_footerBasic__2JtvV .Footer_social__2M4qp > a {
    font-size:15px;
    width:40px;
    height:40px;
    line-height:40px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    border:1px solid #ccc;
    margin:0 8px;
    color:inherit;
    opacity:0.75;
  }
  
  .Footer_footerBasic__2JtvV .Footer_social__2M4qp > a:hover {
    opacity:0.9;
  }
  
  .Footer_footerBasic__2JtvV .Footer_copyright__1ZVtY {
    margin-top:15px;
    text-align:center;
    font-size:13px;
    color:#aaa;
    margin-bottom:0;
  }
.Description_headTitle__3x80H {
    font-size: 69px;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  
  .Description_mainText__2uGum {
    font-size: 22px;
    margin-bottom: 4rem;
    font-weight: 600;
  }

  .Description_bottomText__1bGgw {
    font-size: 22px;
    margin-bottom: 0.75rem;
    font-weight: 600;
  }



  @media screen and (max-width: 955px) {
    .Description_headTitle__3x80H {
      font-size: 40px;
      margin-bottom: 1rem;
    }

    .Description_mainText__2uGum {
      font-size: 18px;
      margin-bottom: 3rem;
    }

    .Description_bottomText__1bGgw {
      font-size: 18px;
      margin-bottom: 0.75rem;
    }
  }



  @media screen and (max-width: 730px) {
    .Description_headTitle__3x80H {
      font-size: 35px;
      margin-bottom: 1rem;
    }

    .Description_mainText__2uGum {
      font-size: 16px;
      margin-bottom: 3rem;
    }

    .Description_bottomText__1bGgw {
      font-size: 16px;
      margin-bottom: 0.75rem;
    }
  }
  

  @media screen and (max-width: 359px) {
    .Description_headTitle__3x80H {
      font-size: 35px;
      margin-bottom: 1rem;
    }

    .Description_mainText__2uGum {
      font-size: 15px;
      margin-bottom: 3rem;
    }

    .Description_bottomText__1bGgw {
      font-size: 15px;
      margin-bottom: 0.75rem;
    }
  }

.Card_column__3SfbY {
    float: left;
    width: 20%;
    padding: 0 2rem;
}

.Card_card__2IT-K {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* padding: 16px; */
    text-align: center;
    background-color: #f1f1f1;
    border-radius: 25px;
    height: 100%;
    /* color: red; */
    color:rgb(50,50,50);
}

.Card_card__2IT-K h2{
    margin: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.Card_card__2IT-K p {
    text-align: left;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-bottom: 0.75rem;
}

.Card_image__30ice {
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}


@media screen and (max-width: 1000px) {
    .Card_column__3SfbY {
      width: 25%;
      padding: 0 1rem;
    }
  }

@media screen and (max-width: 600px) {
    .Card_column__3SfbY {
      width: 70%;
      display: block;
      margin-bottom: 20px;
    }
  }
