.column {
    float: left;
    width: 20%;
    padding: 0 2rem;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* padding: 16px; */
    text-align: center;
    background-color: #f1f1f1;
    border-radius: 25px;
    height: 100%;
    /* color: red; */
    color:rgb(50,50,50);
}

.card h2{
    margin: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.card p {
    text-align: left;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-bottom: 0.75rem;
}

.image {
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}


@media screen and (max-width: 1000px) {
    .column {
      width: 25%;
      padding: 0 1rem;
    }
  }

@media screen and (max-width: 600px) {
    .column {
      width: 70%;
      display: block;
      margin-bottom: 20px;
    }
  }