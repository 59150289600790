.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 94%;
    height: 6rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3%;
    z-index: 10;
  }

  .main-image {
    width: 5%;
    height: 7rem;
    z-index: 0;
  }
  
  .main-image img {
    height: 100%;
    object-fit: cover;
  }